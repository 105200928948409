@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  @font-face {
    font-family: Oswald, sans-serif;
    font-style: normal;
    font-weight: 1 999;
    font-display: swap;
    src: url('/assets/fonts/Oswald/Oswald-VariableFont_wght.ttf')
      format('truetype-variations');
  }

  @font-face {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  }

  :root {
    @apply bg-white text-black;
  }

  html,
  body {
    @apply w-full min-w-80 font-poppins;
  }
}

@layer components {
  .mobile-menu {
    @apply fixed left-0 top-0 z-20 flex h-screen w-full -translate-x-full transform flex-col bg-black pt-16 transition-transform sm:max-w-96 lg:hidden;

    & > .overflow-y-auto {
      scrollbar-color: var(
          --fallback-s,
          oklch(var(--s) / var(--tw-text-opacity, 1))
        )
        oklch(var(--n, var(--b2)) / var(--tw-bg-opacity));
      background: color-mix(
        in oklab,
        oklch(var(--n, var(--b2)) / var(--tw-bg-opacity, 1)) 70%,
        black
      );
    }
  }

  .activity {
    @apply absolute top-0 z-10 flex size-full flex-col items-center justify-center overflow-y-auto text-center;

    p {
      @apply m-0;
    }
  }

  //    .btn-primary {
  //        @apply btn h-auto flex items-center gap-4 px-4 py-3 w-fit rounded-none border border-primary transition-colors text-white bg-primary hover:bg-secondary hover:border-secondary;
  //    }
  //    .btn-secondary {
  //        @apply btn h-auto flex items-center gap-4 px-4 py-3 w-fit rounded-none border border-black transition-colors text-black bg-white hover:bg-secondary hover:border-secondary hover:text-white;
  //    }

  //    .btn-icon {
  //        @apply btn flex items-center justify-center p-1 min-h-4 h-auto rounded-full;
  //    }

  //    .btn-text {
  //        @apply btn-ghost h-auto p-0;
  //    }

  //    .btn-ghost {
  //        @apply btn h-auto shadow-none bg-transparent border-transparent text-white hover:text-secondary hover:bg-transparent hover:border-transparent;
  //    }

  //    .input-primary {
  //        @apply input input-bordered rounded-none shadow-md border-black focus:border-black focus:outline-black w-full max-w-none text-lg placeholder:text-black;
  //    }

  //    .textarea-primary {
  //        @apply textarea textarea-bordered rounded-none shadow-md border-black focus:border-black focus:outline-black w-full max-w-none text-lg placeholder:text-black;
  //    }

  //    .checkbox-primary {
  //        @apply checkbox rounded-none shadow-md border-black;
  //    }

  //    .label a {
  //        @apply hover:text-secondary;
  //    }
}

@layer utilities {
  .lock-body-scroll {
    @apply h-full overflow-hidden;
  }

  .painted-underline {
    @apply relative after:absolute after:left-0 after:top-1 after:h-full after:w-full after:bg-underline after:bg-contain after:bg-bottom after:bg-no-repeat after:content-[''];
  }

  .ripple {
    @apply pointer-events-none absolute block scale-0 transform animate-ripple rounded-full bg-black/35;
  }

  .heading-underline {
    @apply relative mb-5 after:mt-5 after:block after:h-[6px] after:w-full after:max-w-56 after:bg-error after:content-[''];
  }

  .heading-underline-centered {
    @apply heading-underline after:mx-auto;
  }

  .box-button:hover {
    cursor:
      url('/assets/img/cursor-punch.svg') 30 15,
      auto;
  }

  .falling-gallery-image {
    @apply max-h-full max-w-full -translate-x-1/2 -translate-y-1/2 transition duration-1000 md:max-h-none;

    width: auto !important; /* stylelint-disable-line declaration-no-important */
    height: auto !important; /* stylelint-disable-line declaration-no-important */
    inset: 50% !important; /* stylelint-disable-line declaration-no-important */
  }

  .custom-scroll {
    scrollbar-width: thin;
    scrollbar-color: #7f8c8d #fff;
  }

  .custom-scroll::-webkit-scrollbar {
    @apply w-2;
  }

  .custom-scroll::-webkit-scrollbar-track {
    @apply bg-white;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    @apply rounded-none border-none bg-accent;
  }
}
